import axios from 'axios'
import router from '../router/index'
import store from '@/store'
import { MessageBox,Message } from 'element-ui'

const instance =axios.create({
    baseURL:process.env.VUE_APP_BASE_API

})
instance.interceptors.request.use((config)=>{
    sessionStorage.setItem('state',JSON.stringify([{"answer":"","id":"","ans":[]}]))
    let token =  window.sessionStorage.getItem("token");
    if(token){
        config.headers['token']=token
    }
    return config
})
instance.interceptors.response.use((response)=>{

    let data=response.data;

    //二进制直接返回
    if(!data.code){

        return data
    }
    if(data.code===0){
        return response.data
    }
    if(data.code===401){

        store.dispatch('user/logout')

        MessageBox.confirm('登录已过期,是否重新登录？', {
            confirmButtonText: '重新登录',
            showCancelButton:false,
            showClose:false,
            closeOnClickModal:false,
            type: 'warning'
        }).then(() => {
            router.push('/')
            location.reload()
        })

    }
    if(data.code===1){
        Message({
            message: data.msg || '请求失败',
            type: 'error'

        })

        return Promise.reject(data.msg)
    }
})

export default instance
